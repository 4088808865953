<template>
  <div>
    <v-card class="col-6 ml-6 mt-6">
      <v-card-title>
        <span class="blue--text text-h6"> Şirket Bilgileri </span>
      </v-card-title>
      <v-divider />
      <div class="d-flex">
        <div class="col-6 text-left font-weight-black">Şirket Adı:</div>
        <div class="col-6 text-left">{{ companyData.companyName }}</div>
      </div>

      <v-divider />
      <div class="d-flex">
        <div class="col-6 text-left font-weight-black">Adress:</div>
        <div class="col-6 text-left">{{ companyData.adress }}</div>
      </div>

      <v-divider />

      <div class="d-flex">
        <div class="col-6 text-left font-weight-black">Durumu:</div>
        <div class="col-6 text-left">
          {{ companyData.status == "Active" ? "Aktif" : "Pasif" }}
        </div>
      </div>
      <v-divider />
      <div class="d-flex">
        <div class="col-6 text-left font-weight-black">Ekleme Tarihi:</div>
        <div class="col-6 text-left">{{ companyData.createTime }}</div>
      </div>
      <v-divider />
    </v-card>
  </div>
</template>

<script>
import { getCompany } from "@/api/Request/company";

export default {
  data: () => ({
    companyCode: null,

    companyData: {},
  }),
  watch: {},
  created() {},
  mounted() {
    this.selectedFarmFunc();
  },
  methods: {
    selectedFarmFunc() {
      const storage = window.localStorage;
      const userCompany = JSON.parse(
        window.atob(storage.getItem("user_company"))
      );
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;
      this.getCompany();
    },
    getCompany() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
        },
      };

      getCompany(params).then((res) => {
        console.log(res.data);
        this.companyData = res.data.data;
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
#map {
  height: 300px;
  width: 400px;
}
</style>
